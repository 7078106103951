import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { Module } from "vuex";
import { IRootState } from "@monorepo/informationSecurity/src/store";
import { fields } from "@monorepo/utils/src/variables/projectsData/MonitoringBussinessProccesesView/fields";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { IGraphElement } from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/types/graphElement";

export interface IBussinessProcesses {
  name: string;
  version: string;
  status: string;
}

interface ITableState {
  graphFilters: { [key: string]: string };
  graphData: IGraphElement[];
  services: IBussinessProcesses[];
  isServicesLoading: boolean;
  isTableLoading: boolean;
}

const formGraphParams = (graphFilters: { [key: string]: string }, notEncoded = false) => {
  const params = {
    filters: [{ key: "period", value: [graphFilters[fields.PERIOD_FIlTER_FROM], graphFilters[fields.PERIOD_FIlTER_TO]] }],
  };
  return notEncoded ? params : encodeQueryData(params);
};

export const getDefaultState = (): ITableState => {
  return {
    graphFilters: {},
    graphData: [],
    services: [],
    isServicesLoading: false,
    isTableLoading: false,
  };
};

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_GRAPH_MONITORING_CSV;
    case "XLSX":
      return QUERY_PATH.GET_GRAPH_MONITORING_XLS;
    default:
      return QUERY_PATH.GET_GRAPH_MONITORING_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    setGraphFilters(state: ITableState, payload: { [key: string]: string }) {
      state.graphFilters = payload;
    },
    addGraphData(state: ITableState, payload: IGraphElement[]) {
      state.graphData = payload;
    },
    addServices(state: ITableState, payload: IBussinessProcesses[]) {
      state.services = payload;
    },
    addServicesLoading(state: ITableState, payload: boolean) {
      state.isServicesLoading = payload;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    async getServices({ commit }) {
      try {
        commit("addServicesLoading", true);
        const { data } = await getQuery<IBussinessProcesses[]>(`${QUERY_PATH.GET_MONITORING_BUSSINESS_PROCESSES}`);
        if (data !== null) {
          commit("addServices", data || []);
        }
      } catch (e) {
        console.error(e);
      } finally {
        commit("addServicesLoading", false);
      }
    },
    async getGraphData({ state, commit, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const queryParams = formGraphParams(state.graphFilters, true);
      const { data } = await getQuery<IArchiveElement[]>(QUERY_PATH.GET_GRAPH_MONITORING_LIST, queryParams).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addGraphData", data || []);
      }
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      const queryParams = formGraphParams(state.graphFilters);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams as string), { root: true });
    },
  },
  getters: {
    graphFilters: (state) => state.graphFilters,
    graphData: (state) => state.graphData,
    services: (state) => state.services,
    isServicesLoading: (state) => state.isServicesLoading,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
